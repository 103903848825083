import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Button, Icon, Dialog, Circle, Skeleton, Popup, Swipe, SwipeItem, Tab, Tabs, Pagination, Progress, CountDown, Switch, Picker } from 'vant'
import AOS from 'aos'
import 'aos/dist/aos.css'
import plugin from './plugins/flexible'
import Utils from './utils/utils'
import snackbar from 'snackbar'
import 'vant/lib/index.css' // vant 全局引入样式

axios.defaults.baseURL = store.state.base_url + 'api/web'
axios.defaults.timeout = 5000
axios.defaults.headers.Authorization = 'Bearer ' + localStorage.token
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'

const app = createApp(App)
app.config.devtools = true
app.config.productionTip = false

router.beforeEach((to, from, next) => {
  store.commit('is_niming')
  if (to.name === 'goods' || to.name === 'index') {
    window.scrollTo(0, 0)
  }
  // console.log(to.query)
  if (to.name === 'agreement') {
    next()
  } else if (to.name === 'index_pc') {
    if (Utils.is_mobile()) {
      router.push({ name: 'index', query: to.query })
    } else {
      next()
    }
  } else if (to.name === 'index_wx') {
    if (!Utils.is_mobile()) {
      router.push({ name: 'index_pc', query: to.query })
    } else {
      if (!Utils.is_weixin()) {
        router.push({ name: 'index', query: to.query })
      } else {
        next()
      }
    }
  } else {
    if (!Utils.is_mobile()) {
      router.push({ name: 'index_pc', query: to.query })
    } else {
      if (Utils.is_weixin() && !(to.name === 'to_sth' || to.name === 'to_app' || to.name === 'to_xcx')) {
        // vue.$router.replace()
        router.push({ name: 'index_wx', query: to.query })
      } else {
        if (from.name === 'name_xing') {
          if (to.name === 'index') {
            from.meta.keepAlive = false
          } else {
            from.meta.keepAlive = true
          }
        } else if (from.name === 'fit') {
          if (to.name === 'name_xing') {
            from.meta.keepAlive = false
          } else {
            from.meta.keepAlive = true
          }
        } else if (from.name === 'name_list') {
          if (to.name === 'name_xing') {
            from.meta.keepAlive = false
          } else {
            from.meta.keepAlive = true
          }
        } else if (from.name === 'name_mark') {
          if (to.name === 'mine') {
            from.meta.keepAlive = false
          } else {
            from.meta.keepAlive = true
          }
        } else if (from.name === 'orders') {
          if (to.name === 'mine') {
            from.meta.keepAlive = false
          } else {
            from.meta.keepAlive = true
          }
        }
        next()
      }
    }
  }
})
app.use(store).use(router).use(snackbar).use(Utils).use(plugin).use(Button).use(Icon).use(Dialog).use(Circle).use(Skeleton).use(Popup).use(Swipe).use(SwipeItem).use(Tab).use(Tabs).use(Pagination).use(Progress).use(CountDown).use(Switch).use(Picker).use(AOS).mount('#app')
AOS.init({
  offset: 120,
  duration: 1200,
  easing: 'ease-in-sine',
  delay: 360
})
