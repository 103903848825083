import snackbar from 'snackbar'
import wx from 'weixin-js-sdk'
import axios from 'axios'
import router from '@/router/index.js'

import { Dialog, Toast } from 'vant'

const Utils = {
  is_mobile () {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
  },
  close_window () {
    wx.closeWindow()
  },
  is_weixin () {
    const flag = /(micromessenger)/i.test(navigator.userAgent)
    return flag
  },
  alert (e) {
    Dialog.alert({
      title: e.title ? e.title : '提示',
      message: e.content ? e.content : '输入内容不可为空',
      confirmButtonText: '朕知道了',
      confirmButtonColor: '#4fc08d'
    })
  },
  show_loading () {
    Toast.loading({
      message: '加载中..',
      forbidClick: true
    })
  },
  go_to (e) {
    // if (!localStorage.getItem('token') && needLogin) {
    //   this.show.zc = true
    //   return
    // }
    router.push({ name: e.currentTarget.dataset.value })
  },
  go_to_index () {
    location.href = 'https://www.ssqm.cn/'
  },
  go_to_app (tag, sid) {
    const urlStr = localStorage.getItem('token') ? '/log_to_sth/' : '/log_to_sth_unlogin/'
    if (!sid) {
      sid = localStorage.getItem('sid')
    }
    axios.post(urlStr, { sth: 'app', tag: tag, sid: sid, is_ios: Utils.is_ios() })
      .then(res => {
      // console.log(res)
        if (res.data.code !== 200) {
          Utils.alert({ content: res.data.msg })
          return
        }
        if (Utils.is_ios()) {
          location.href = 'https://apps.apple.com/cn/app/id1564874607'
        } else {
          router.push({ name: 'index' })
        }
      })
  },
  go_to_xcx (tag, sid) {
    const urlStr = localStorage.getItem('token') ? '/log_to_sth/' : '/log_to_sth_unlogin/'
    if (!sid) {
      sid = localStorage.getItem('sid')
    }
    axios.post(urlStr, { sth: 'xcx', tag: tag, sid: sid, is_ios: Utils.is_ios() })
      .then(res => {
        if (res.data.code !== 200) {
          Utils.alert({ content: res.data.msg })
          return
        }
        location.href = res.data.data.store_info.urlscheme
      })
  },
  go_to_goods_index (e) {
    const PageAndTag = e.currentTarget.dataset.value.split('|')
    router.push({ name: 'goods', query: { way: 'button', page: PageAndTag[0], tag: PageAndTag[1] } })
  },
  show_ask_goods (info) {
    Dialog.confirm({
      title: '权限不足',
      showCancelButton: true,
      confirmButtonText: '确定',
      message: '进一步了解大数据起名功能',
      confirmButtonColor: '#4fc08d'
    }).then(() => {
      router.push({ name: 'goods', query: { way: 'alert', page: info.page, tag: info.tag } })
    }).catch(() => {
      // on cancel
      console.log('cancel')
    })
  },
  show_snackbar (desc) {
    // const snackbar = require('snackbar')
    snackbar.duration = 5000
    snackbar.gap = 250
    snackbar.show(desc)
  },

  is_need_alert_tips  (e, desc) {
    const tipKey = 'tip_' + e
    if (!localStorage.getItem(tipKey)) {
      localStorage.setItem(tipKey, 1)
      Utils.show_snackbar(desc)
    }
  },

  alert_tip_list (pageStr, tipList) {
    for (const i in tipList) {
      if (tipList[i].tag === 'must') {
        Utils.show_snackbar(tipList[i].desc)
        return
      }
      const tipKey = 'tip_' + pageStr + '_' + tipList[i].tag
      if (!localStorage.getItem(tipKey)) {
        localStorage.setItem(tipKey, 1)
        Utils.show_snackbar(tipList[i].desc)
        return
      }
    }
  },

  is_ios () {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    return isiOS
  },
  // loading_app () {
  //   const urlStr = localStorage.getItem('token') ? '/log_sth/' : '/log_sth_unlogin/'
  //   axios.post(urlStr, { tag: 'loading_app', is_ios: Utils.is_ios() })
  //     .then(res => {
  //       if (res.data.code !== 200) {
  //         Utils.alert({ content: res.data.msg })
  //         return
  //       }
  //       if (Utils.is_ios()) {
  //         Utils.show_snackbar('用户的不错还望 好评啊~')
  //         location.href = 'https://apps.apple.com/cn/app/id1579737081'
  //       } else {
  //         Utils.show_snackbar('您即将跳转腾讯应用宝~')
  //         location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.kaiwuapp.app'
  //       }
  //     })
  // },
  update_wx_share_info (fullPath) {
    wx.ready(() => {
      const hasQuan = fullPath.indexOf('/quan') !== -1
      // 微信分享的数据
      const shareData = {
        imgUrl: 'https://www.kaiwuapp.com/static/img/share_logo.png', // 分享显示的缩略图地址
        link: hasQuan ? 'https://www.kaiwuapp.com' + fullPath : 'https://www.daokaiwuapptingtuxiang.com/', // 分享地址
        title: hasQuan ? '我在领会员券，请助力' : '开悟之坡成长社区', // 分享标题
        desc: hasQuan ? '开悟之坡成长社区' : '遇见聪明又韧性的你', // 分享描述
        success: function () {
          // 分享成功可以做相应的数据处理
          console.log('分享成功')
        },
        fail: function () {
          console.log('调用失败')
        },
        complete: function () {
          console.log('调用结束')
        }
      }
      wx.updateTimelineShareData(shareData)
      wx.updateAppMessageShareData(shareData)
    })
  },

  set_local_sid (sid) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('sid')
      localStorage.removeItem('st')
    } else if (sid) {
      localStorage.setItem('sid', sid)
      localStorage.setItem('st', new Date().getTime())
    }
  },
  has_token () {
    const token = localStorage.getItem('token')
    return token !== null && token !== ''
  },
  set_local_qid (qid) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('qid')
      localStorage.removeItem('qt')
    } else if (qid) {
      localStorage.setItem('qid', qid)
      localStorage.setItem('qt', new Date().getTime())
    }
  },
  go_to_login_fwh (way) {
    location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx509025bfa6f5cc6a&redirect_uri=https://www.kaiwuapp.com/login/&response_type=code&scope=snsapi_' + way + '&state=' + way + '#wechat_redirect'
  },
  go_to_want_page () {
    if (localStorage.getItem('want_page') === null || localStorage.getItem('want_page') === '') {
      // router.replace({ name: 'index', query: {} })
      location.href = 'https://www.ssqm.cn/'
    } else {
      // router.replace({ path: localStorage.getItem('want_page') })
      location.href = 'https://www.ssqm.cn' + localStorage.getItem('want_page')
    }
    localStorage.removeItem('want_page')
  },
  formatNumber (n) {
    n = n.toString()
    return n[1] ? n : `0${n}`
  },
  format_time_easy (timeStr) {
    const nowDate = new Date() // 新建一个日期对象，默认现在的时间
    const strDate = new Date(timeStr.replace(/-/g, '/'))
    // new Date(dateTime).getTime()
    const localOffset = nowDate.getTimezoneOffset() * 60000 // 获得当地时间偏移的毫秒数

    const d = new Date(strDate - localOffset)

    const minutes = (nowDate.getTime() - d.getTime()) / (1000 * 60)
    // const aa =[d.getMonth() + 1, d.getDate()].map(formatNumber).join('-') + ' ' + [d.getHours(), d.getMinutes()].map(formatNumber).join(':')
    const currentDayZeroStr = `${nowDate.getFullYear()}-${nowDate.getMonth() === 9 || nowDate.getMonth() === 10 || nowDate.getMonth() === 11 ? nowDate.getMonth() + 1 : '0' + (nowDate.getMonth() + 1)}-${nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()} 00:00:00`
    const currentDayZero = new Date(currentDayZeroStr.replace(/-/g, '/'))
    const minutesZero = (currentDayZero.getTime() + localOffset - strDate.getTime()) / (1000 * 60)

    if (minutes < 1) {
      return '刚刚'
    } else if (minutes < 60) {
      return parseInt(minutes).toString() + '分钟前'
    } else if (minutesZero < 0) {
      return '今天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else if (minutesZero < 24 * 60) {
      return '昨天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else if (minutesZero < 48 * 60) {
      return '前天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else {
      const isNowYear = d.getFullYear() === nowDate.getFullYear()
      if (isNowYear) {
        return [d.getMonth() + 1, d.getDate()].map(Utils.formatNumber).join('-') + ' ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
      } else {
        return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(Utils.formatNumber).join('-') + ' ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
      }
    }
  }
}
export default Utils
