<template>
  <div id="app">
    <router-view v-slot="{ Component }">
    <keep-alive include="index,mine,group_index">
      <component :is="Component" />
    </keep-alive>
  </router-view>
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import router from '@/router/index.js'

import { Dialog } from 'vant'

export default {
  store,
  mounted (e) {
    // Utils.alert(333)
    store.commit('is_niming')
    if (localStorage.getItem('token')) {
      const data = { launch_info: { scene: null, brand: '' } }
      axios.post('/log_come/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          const needTips = res.data.data.need_tips
          console.log(needTips)
          // res.data.data.need_tips.need = true
          // res.data.data.need_tips.content = 'aadddddaa'
          if (needTips.need) {
            Dialog.confirm({
              title: '绑定账号',
              showCancelButton: true,
              message: needTips.content,
              confirmButtonText: '好的',
              cancelButtonText: '暂不',
              confirmButtonColor: '#4fc08d',
              cancelButtonColor: '#ccc'
            }).then(() => {
              router.push({ name: 'bind_tips' })
            }).catch(() => {
              Utils.show_snackbar('稍后您可以在【我的-绑定账号页面】完成绑定')
            })
          }
        })
    } else {
      const data = { launch_info: { scene: null, brand: '' } }
      axios.post('/log_come_unlogin/', data)
        .then(res => {
          console.log(res)
        })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 10rem;
  font-size: 0.4rem;

}

.page{
  background-color: #f8f8f8;
  padding-bottom: 2rem;
}

#nav {
  padding: 0.3rem;
}
#nav a{
  font-weight: bold;
  color: #2c3e50;
}
#nav .router-link-exact-active{
  color: #42b983;
}
*{
  padding: 0;
  margin: 0;
}

button{
  border: none;
}
a{
  text-decoration: none;
}

a:link,a:visited{
  text-decoration: none;
  color: #212b36;
}
a:hover{
  color: #f90;
}
ul,li{
  list-style: none;
}

.mod,.mpd{
  display: block;
  margin: 0.4rem 0rem;
  padding: 0.60rem 0rem;
  text-align:center;
  background-color: #fefefe;
  border-radius: 0.10rem;
}
.mcd{
  display: block;
  margin: 0.4rem 0rem;
  padding: 0.60rem 0rem  0.60rem 0.4rem;
  text-align:left;
  background-color: #fefefe;
  border-radius: 0.10rem;
}
.med{
  display: block;
  margin: 0.4rem 0rem;
  padding: 0.60rem 0rem;
  text-align:left;
  background-color: #fefefe;
  border-radius: 0.10rem;
}
.mbd{
  display: block;
  margin: 0.4rem 0rem;
  padding: 0.60rem 0.5rem;
  text-align:left;
  background-color: #fefefe;
  border-radius: 0.10rem;
}

.mod input[type="text"],.mpd input{
  width: 5.2rem;
  border-radius: 0.40rem;
  background-color: #f8f8f8;
  text-align: left;
  display: inline-block;
  border-left: 0.8rem solid #f8f8f8;
  border-right: none;
  border-top: none;
  border-bottom: none;
  margin-top: 0.20rem;
  height: 0.90rem;
  line-height: 0.90rem;
}

.color_1{
  border:0.02rem dashed #FFB6C1;
}
.color_2{
  border:0.02rem dashed #DBBFD1;
}
.color_3{
  border:0.02rem dashed #C1C5DD;
}
.color_4{
  border:0.02rem dashed #A6CBE9;
}
.color_5{
  border:0.02rem dashed #81D3F8;
}

.word_color_1{
  color:#FFB6C1;
}
.word_color_2{
   color: #DBBFD1;
}
.word_color_3{
   color: #C1C5DD;
}
.word_color_4{
   color: #A6CBE9;
}
.word_color_5{
   color: #81D3F8;
}

.touming_color_1{
  background-color:rgba(255,182,193,0.5);

}
.touming_color_2{
  background-color:rgba(219,191,209,0.5);
}
.touming_color_3{
  background-color:rgba(193,197,221,0.5);
}
.touming_color_4{
  background-color:rgba(166,203,233,0.5);
}
.touming_color_5{
  background-color:rgba(129,211,248,0.5);
}

.background_color_1{
  background-color:#FFB6C1;
  color: #fefefe;
  border: 0.02rem solid #FFB6C1;

}
.background_color_2{
  background-color: #DBBFD1;
  color: #fefefe;
  border: 0.02rem solid #DBBFD1;

}
.background_color_3{
  background-color: #C1C5DD;
  color: #fefefe;
  border: 0.02rem solid #C1C5DD;

}
.background_color_4{
  background-color: #A6CBE9;
  color: #fefefe;
  border: 0.02rem solid #A6CBE9;

}
.background_color_5{
  background-color: #81D3F8;
  border: 0.02rem solid #81D3F8;
  color: #fefefe;
}

.tag{
  display: inline-block;
  height: 0.36rem;
  background-color: #f8f8f8;
  border-radius: 0.30rem;
  line-height: 0.36rem;
  font-size: 0.24rem;
  padding: 0.10rem 0.30rem;
  margin-right: 0.20rem;
}
.black_tag{
  display: inline-block;
  padding: 0.16rem 0.26rem;
  font-size: 0.23rem;
  line-height:  0.26rem;
  margin: 0.10rem 0.16rem 0rem 0.16rem;
  background: rgba(85,85,85,0.18);
  border-radius: 0.30rem;
  color: #fefefe;
}
.girl{
  color: #E37994;
}
.boy{
  color: #74BCE0;
}
.qing{
  color: #69B1AA;
}

.head{
  width: 10rem;
  text-align: center;
  height: 4.8rem;
  background-image: linear-gradient(rgb(70, 130, 180), #81D3F8);
}
.head .title{
  height: 2.5rem;
  vertical-align: bottom;

}
.head p{
  color: #fefefe;
  display: inline-block;
}
.head .title p{
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-top: 1.2rem;
  vertical-align: bottom;
}
.head .title span.b{
  font-size: 0.4rem;
  line-height: 0.4rem;
  vertical-align: bottom;
}
.head .about{
  height: 2rem;
}
.head .about p{
  font-size: 0.5rem;
  line-height: 0.64rem;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.head .about .l1{
  height: 2rem;
  padding-top: 0.8rem;
}
.head .about .l2{
  height: 1.2rem;
}
.head .about .l1 p{
  margin: 0rem 0rem;
}
.head .about .l2 p{
  margin: 0.4rem 0.08rem 0rem 0.08rem;
}
.head .about .tag{
  display: inline-block;
  padding: 0.12rem 0.3rem;
  font-size: 0.32rem;
  line-height: 0.4rem;
  margin: 0rem 0.2rem;
  background: rgb(100, 184, 214);
  color: #f8f8f8;
}
.head .about p.black_tag{
  display: inline-block;
  padding: 0.2rem 0.32rem;
  font-size: 0.4rem;
  line-height:  0.4rem;
  margin: 0.12rem 0.2rem 0rem 0.2rem;
  background: rgba(85,85,85,0.18);
  border-radius: 0.4rem;
}
.warn_tips{
  margin: 0.6rem 0rem 0.8rem 0rem;
  display: block;
  text-align: center;
  font-size: 0.4rem;
  color: orange;
}
.blue_tips{
  margin: 0.6rem 0rem 0.8rem 0rem;
  display: block;
  text-align: center;
  font-size: 0.4rem;
  color: #6a98d3;
}
.more_tips{
  margin: 0.8rem 0rem;
  display: block;
  text-align: center;
  font-size: 0.4rem;
  color: #bebfc0;
}

p.end,p.tips{
  margin: 0.36rem 0rem;
  display: block;
  text-align: center;
  font-size: 0.4rem;
  color: #bebfc0;
}
.warn{
  color: orange;
}
.orange{
  color: orange
}
.gray{
  color: gray
}
.danger{
  color: red;
}
.blue{
  color: #569CD6;
}
.green{
  color: green;
}

.home_footer{
  z-index: 2;
  position: fixed;
  width: 10rem;
  height: 1.5rem;
  line-height: 1.5rem;
  left: 0;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.home_footer .icons{
  font-size: 0.5rem;
  flex: 1;
  text-align: center;
  border-top: 0.01rem solid #dedede;
  /* border-left: 0.01rem solid lightgray; */
}
.home_footer .un_index{
  border-left: 0.01rem solid #dedede;
}

.home_footer .icons a{
  display: block;
  color: #888;
  background:#f6f6f6;
}

.home_footer .active_icons a{
  color: #81D3F8;
}
.foot{
  width: 10rem;
  height:2.12rem;
  position: fixed;
  bottom: 0rem;
  background-color: #fefefe;
  z-index: 10;
  border-top: 0.02rem solid #f8f8f8;
}

.foot button{
  position: relative;
  vertical-align: middle;
  display: inline-block;
}

.foot button.w1{
  width: 7.98rem;
  margin: 0.4rem 1rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 0.48rem;
}
.foot button.w2{
  width: 3.98rem;
  margin: 0.40rem 0.48rem ;
  height:1rem;
  line-height: 1rem;
  border-radius: 0.48rem;
  float: left;
}

.foot button.black{
  background-color: #212B36;
  color: #fefefe;
  border: 0.01rem solid #212B36;
}
.foot button.white{
  background-color: #fefefe;
  color: #212B36;
  border: 0.01rem solid #212B36;
}
.foot button.gray{
  background-color: #C8CBC8;
  color: #f9f9f9;
}
.foot button.pink{
  background-color: pink;
  color: #f6f9f8;
  border: 0.01rem solid pink;
}

button.in_body{
  display: block;
  width: 6rem;
  margin: 0.72rem auto 0.48rem auto;
  height: 0.9rem;
  line-height: 0.9rem;
  border-radius: 0.48rem;
  font-size: 0.42rem;
}
.in_body_w2_div{
  margin: 0.6rem  0rem 0.2rem  0rem;
}

button.in_body_w2{
  display: inline-block;
  width: 3.6rem;
  margin: 0rem  0.3rem;
  height: 0.9rem;
  line-height: 0.9rem;
  border-radius: 0.48rem;
  font-size: 0.4rem;
  font-weight: bold;
}
button.black{
  background-color: #212B36;
  color: #fefefe;
  border: 0.02rem solid #212B36;
}
button.white{
  background-color: #fefefe;
  color: #212B36;
  border: 0.02rem solid #fefefe;

}
button.gray{
  background-color: #C8CBC8;
  color: #f9f9f9;
  border: 0rem;
}
button.wx_pay_color{
  background-color: #42b983;
  color: #f9f9f9;
  border: 0rem;
}
button.lightgray{
  border: 0.02rem solid #f8f8f8;
  color: #777;
  font-size: 0.4rem;
  background-color: #f8f8f8;
}
button.pink{
  background-color: pink;
  color: #fefefe;
  border: 0.02rem solid pink;
}
button.vip_color{
  background-color: #F9AD39;
  color: #fefefe;
  border: 0.02rem solid #F9AD39;
}
button.green{
  background-color: #42b983;
  color: #fefefe;
  border: 0.02rem solid #42b983;
}

#chengyu,#people{
  margin-top: 0.5rem;
}
#chengyu li,#people li {
  margin: 0.1rem 0rem;
  text-align: left;
  padding: 0.1rem 0.6rem;
}

#chengyu li div,#people li div {
  display: inline-block;
  vertical-align: middle;
}

#chengyu li .word,#people li .word {
  width: 2rem;
  margin-right: 0.3rem;
}
#people li .name {
  text-align: center;
}

#chengyu li .desc{
  width: 6rem;
  color: #999;
  border-bottom: 0.02rem dashed #ccc;
  padding: 0.3rem 0rem;
}
#people li .desc {
  width: 5.6rem;
  color: #999;
  border-bottom: 0.02rem dashed #ccc;
  padding: 0.3rem 0rem;
  text-align: center;
}

#people li .right{
  color: #ccc;
  margin-left: 0.3rem;

}
#ciyu {
  text-align: left;
  margin: 0.5rem;
}
#ciyu li{
  display: inline-block;
  margin: 0.4rem 0.4rem;
}
#zi_head{
  padding: 0.30rem 0.40rem;
  background: #ffffff;
  margin: 0.30rem 0rem;
  text-align: left;
}
#zi_head .in_line{
  text-align: center;
  margin: 0.20rem 0rem;
}
#zi_head .in_line p{
  display: inline-block;
  margin: 0.10rem 0.20rem;
}
#zi_head .key{
  color: #ccc;
}
#zi_head .value{
  margin: 0rem 0.20rem;
}

#zi_head .zi_tag{
  display: inline-block;
  padding: 0.16rem 0.20rem;
  font-size: 0.26rem;
  line-height:  0.26rem;
  margin: 0.10rem 0.10rem;
  background: rgba(37, 130, 173, 0.3);
  border-radius: 0.30rem;
  color: #fefefe;
}

.mod3 {
  margin: 0.40rem 0rem;
  padding: 0.30rem 0.45rem 0.60rem 0.45rem;
  background: #fefefe;
  border-radius: 0.10rem;
  text-align: left;
}

.mod3 .title, .mod4 .title{
  width: 8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  margin: 0.20rem auto 0.40rem auto;
  text-align: center;
  display: block;
  background: rgba(37, 130, 173, 0.2);
  border-radius: 2rem;
  color: #fefefe;
}
.mod3 .one_mean{
  border-bottom: 0.02rem dashed #eee;
  padding: 0.4rem;
}
.mod3 .one_mean .ex2{
  margin-left: 0.40rem;
  color: #ccc;
}
.mod3 .zi{
  text-align: center;
  display: block;
}
.mod3 .mean_yin{
  margin-top: 0.40rem;
}
.shiju2{
  margin:0.3rem 0.3rem;
  padding: 0.2rem 0.45rem;
  text-align: center;
  background-color: #fefefe;
}
button.to_be_vip{
  background-color: #F9AD39;
  width: 9rem;
  height: 1.8rem;
  margin: 0.6rem 0.5rem 0.2rem 0.5rem;
  color: #fefefe;
  font-size: 0.48rem;
  border: 0.06rem solid #fefefe;
  border-radius: 0.2rem;
  padding-right: 0.5rem;
  background-position: 8.2rem center;
  background-size: 0.4rem 0.5rem;
  background-repeat: no-repeat;
  background-image: url("assets/img/to_rhght_ee.png");

}
button.unvip_to_xing{
  background-color:#42b983;
  width: 9rem;
  height: 1.8rem;
  margin: 0.6rem 0.5rem 0.2rem 0.5rem;
  color: #fefefe;
  font-size: 0.48rem;
  border: 0.06rem solid #fefefe;
  border-radius: 0.2rem;
  padding-right: 0.5rem;

}
.van-dialog__confirm, .van-dialog__confirm:active{
  color: #42b983;
}
.van-dialog__message{
  font-size: 0.42rem;
}

.body_slogan{
  background-image: linear-gradient(rgb(70, 130, 180), #81D3F8);
  color: #fefefe;
  text-align: center;
  padding-top:0.5rem;
}
.body_slogan div{
  color: #fefefe;
}
.body_slogan span{
  color: #fefefe;
}

.body_slogan .title{
  font-size:1rem;
  line-height:1rem;
  height: 1rem;
  padding-top:0.4rem;
}

.body_slogan .content{
  height: 4rem;
  position: relative;
  font-size:0.42rem;
  overflow:hidden;
}

.body_slogan .content .round{
  position: absolute;
  background-color: rgba(255,255,255,0.2);
  width: 18rem;
  height: 18rem;
  border-radius: 50rem;
  bottom: -15rem;
  left: -4.1rem;
}
.body_slogan .content .round .desc{
  margin:1.2rem auto 0.5rem auto;
  font-size: 0.5rem;
  text-align: center;
}
#last_right_a{
  bottom: 1.9rem;
}
#last_right_b{
  bottom: 2.4rem;
}
#last_right_c{
  bottom: 0.8rem;
}
.last_right{
  position: fixed;
  right: 0.4rem;
  width: 1.1rem;
}
.last_right li{
  height: 1.1rem;
  border-radius: 0.73rem;
  background-color: #555;
  margin-top: 0.4rem;
  width: 1.1rem;
  box-shadow:0.03rem 0.03rem 0.08rem #555;
}
.last_right .kefu{
  background-color: #E37994;
}
.last_right .home{
  background-color: #42b983;
}
.last_right li img{
  width: 0.7rem;
  height: 0.7rem;
  margin: 0.18rem;
}
.user_list_center{
  text-align: center;
  margin-top: 0.4rem;
}
.snackbar {
  display: inline-block;
  position: fixed;
  background: #212B36;
  color: #f1f1f1;
  width: 10rem;
  padding: 0.4rem 0.6rem 0.6rem 0.6rem;
  box-sizing: border-box;
  bottom: 0rem;
  left: 0rem;
  font-size: 0.42rem;
  z-index: 1000;
  -webkit-transition: visibility 0.3s, -webkit-transform 0.3s;
  transition: visibility 0.3s, -webkit-transform 0.3s;
  transition: visibility 0.3s, transform 0.3s;
  transition: visibility 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  visibility: hidden;
  will-change: transform;
}
.snackbar--visible {
  visibility: visible;
  -webkit-transform: none;
          transform: none;
}
#to_app_div_small{
  position: absolute;
  background-color: #81D3F8;
  color: #fefefe;
  top: 0rem;
  right: 0rem;
  height: 1.3rem;
  width: 1.3rem;
  line-height: 1.3rem;
  font-size: 0.4rem;
  margin: 0.3rem;
  border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.13rem #888888;
}
.green_tips{
  text-align: left;
  margin: 0.6rem 0rem 0.2rem 0.6rem;

}
.green_tips span{
  margin-left: 0.3rem;
  color: grey;
}
.word_color_jin{
  color: #ffd200
}
.word_color_mu{
  color: #bae4a3;
}
.word_color_shui{
  color: #93b7ff;
}
.word_color_huo{
  color: #ffa200;
}
.word_color_tu{
  color: #af8673;
}
.background_color_jin{
  background-color: #ffd200;
  color: fefefe;
}
.background_color_mu{
  background-color: #bae4a3;
  color: fefefe;
}
.background_color_shui{
  background-color: #93b7ff;
  color: fefefe;
}
.background_color_huo{
  background-color: #ffa200;
  color: fefefe;
}
.background_color_tu{
  background-color: #af8673;
  color: fefefe;
}
</style>
