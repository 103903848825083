<template>
  <div class="mod">
    <input v-model="name_str" type="text" placeholder="输入想到的名字" />
    <button class="black in_body"  @click="get_name_str" >姓名分析</button>
  </div>
</template>

<script>
import Utils from '@/utils/utils'
import router from '@/router/index.js'

export default {
  name: 'SearchName',
  data () {
    return {
      name_str: ''
    }
  },
  methods: {
    get_name_str (e) {
      if (!this.name_str.length) {
        Utils.alert({ content: '输入内容不可为空' })
        return
      }
      router.push({ name: 'name_detail', query: { name: this.name_str } })
    }
  }
}
</script>
