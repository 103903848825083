import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/index',
    name: 'index_pc',
    component: () => import('../views/other/index_pc.vue')
  },
  {
    path: '/index_wx',
    name: 'index_wx',
    component: () => import('../views/other/index_wx.vue')
  },
  {
    path: '/mine',
    name: 'mine_index',
    component: () => import('../views/mine/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/bind_tips',
    name: 'bind_tips',
    component: () => import('../views/mine/bind_tips.vue')
  },
  {
    path: '/group',
    name: 'group_index',
    component: () => import('../views/group/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/group_worth',
    name: 'group_worth',
    component: () => import('../views/group/worth.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/group_about',
    name: 'group_about',
    component: () => import('../views/group/about.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/group_how',
    name: 'group_how',
    component: () => import('../views/group/how.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/group_qa',
    name: 'group_qa',
    component: () => import('../views/group/qa.vue')
  },
  {
    path: '/group/exam',
    name: 'group_exam',
    component: () => import('../views/group/exam.vue')
  },
  {
    path: '/name/detail',
    name: 'name_detail',
    component: () => import('../views/name/detail.vue')
  },
  {
    path: '/xing',
    name: 'name_xing',
    component: () => import('../views/name/xing.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/xing_unvip',
    name: 'name_xing_unvip',
    component: () => import('../views/name/xing_unvip.vue')
  },
  {
    path: '/name/fit',
    name: 'name_fit',
    component: () => import('../views/name/fit.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/name/wuxing',
    name: 'name_wuxing',
    component: () => import('../views/name/wuxing.vue')
  },
  {
    path: '/name/fumu',
    name: 'name_fumu',
    component: () => import('../views/name/fumu.vue')
  },
  {
    path: '/name/list',
    name: 'name_list',
    component: () => import('../views/name/list.vue')
  },
  {
    path: '/name/mark',
    name: 'name_mark',
    component: () => import('../views/name/mark.vue')
  },
  {
    path: '/pay/goods',
    name: 'goods',
    component: () => import('../views/pay/goods.vue')
  },
  {
    path: '/pay/order',
    name: 'order_detail',
    component: () => import('../views/pay/order_detail.vue')
  },
  {
    path: '/pay/order_find',
    name: 'order_find',
    component: () => import('../views/pay/order_find.vue')
  },
  {
    path: '/pay/orders',
    name: 'orders',
    component: () => import('../views/pay/orders.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/other/agreement.vue')
  },
  {
    path: '/to',
    name: 'to_sth',
    component: () => import('../views/other/to_sth.vue')
  },
  {
    path: '/to_app',
    name: 'to_app',
    component: () => import('../views/other/to_app.vue')
  },
  {
    path: '/to_xcx',
    name: 'to_xcx',
    component: () => import('../views/other/to_xcx.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/other/404.vue')
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

export default router
