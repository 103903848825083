<template>
  <div v-if="!can_click" :class="'name_head background_' + name_info.color">
    <div class="title">
      <p class="">{{name_info.word}}</p>
    </div>
    <div class="about">
      <div class="l1" v-html="name_info.warn_p"></div>
    </div>
  </div>
  <router-link v-else :to="{name : 'name_detail', query : {name : name_info.word}}" :class="'name_head background_' + name_info.color" >
    <div class="title">
      <p class="">{{name_info.word}}</p>
    </div>
    <div class="about">
      <div class="l1" v-html="name_info.warn_p"></div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'NameHead',
  props: {
    name_info: Map,
    can_click: Boolean
  }
}
</script>
<style>
.name_head{
  width: 10rem;
  text-align: center;
  height: 4.8rem;
  color: #fefefe;
}
.name_head .title{
  height: 2.5rem;
  vertical-align: bottom;

}
.name_head p{
  color: #fefefe;
  display: inline-block;
}
.name_head .warn{
  background: #fefefe;
  padding: 0.05rem 0.15rem;
  margin: 0rem 0.06rem;
  border-radius: 0.3rem;
  font-size: 0.45rem;
}
.name_head .title p{
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-top: 1.2rem;
  vertical-align: bottom;
}
.name_head .about{
  height: 2rem;
}
.name_head .about p{
  font-size: 0.5rem;
  line-height: 0.64rem;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.name_head .about .l1{
  height: 2rem;
  padding-top: 0.8rem;
}

</style>
