import { createStore } from 'vuex'

export default createStore({
  state: {
    // base_url: 'http://0.0.0.0:5000/',
    // base_img: 'http://0.0.0.0:5000/static/img/',
    base_url: 'https://www.ssqm.cn/',
    base_img: 'https://www.ssqm.cn/static/img/',
    niming: true
  },
  mutations: {
    is_niming () {
      // console.log(localStorage.token)
      if (localStorage.token) {
        this.state.niming = false
      } else {
        this.state.niming = true
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
