<template>
  <div class="page">
    <div v-if="niming">
      <p class="tips">您还没有登录呢~</p>
        <div v-if="what_login" class="phone" >
          <div><input  v-model="phone"  type="text" placeholder="输入手机号" /></div>
          <div>
            <input v-model="yanzhengma" type="text" placeholder="验证码" class="yanzhengma" />
            <button :disabled="disabled" @click="get_yanzhengma" class="sendcode">{{btntxt}}</button>
          </div>
          <button @click="login_by_phone" class="black in_body" >确 定</button>
        </div>
        <div v-else>
          <div class='login'>
            <div class="title">花样起名</div>
            <div class="content">
              <div class="round">
                <div class="desc">
                  <span>用数据拓展起名思路</span>
                </div>
                <div class="ex">
                  <span class="name1">石成玉</span>
                  <span class="name2">夏染秋</span>
                  <span class="name3">林见鹿</span>
                  <span class="name4">史如镜</span>
                </div>
              </div>
            </div>
          </div>
          <div class="agreement_div">
            <input v-model='is_agree'  type="checkbox" name="default"> <label for="default">同意</label>
            <router-link class="" :to="{name:'agreement',query:{type:'agreement'}}"><span>用户协议</span></router-link>
            <span>和</span>
            <router-link :to="{name:'agreement',query:{type:'secret'}}"><span>隐私政策</span></router-link>
          </div>
          <div class="in_body_w2_div">
            <button @click="to_login" class="green in_body_w2" >手机登录</button>
            <button :disabled="disabled2" @click="login_by_nothing" class="black in_body_w2" >测试号体验</button>
          </div>
        </div>
    </div>
    <div class="phone" v-else>
      <p class="login_tips">您还没有绑定呢~</p>
      <div><input  v-model="phone"  type="text" placeholder="输入手机号" /></div>

      <div>
        <input v-model="yanzhengma" type="text" placeholder="验证码" class="yanzhengma" />
        <button :disabled="disabled" @click="get_yanzhengma" class="sendcode">{{btntxt}}</button>
      </div>
      <button  @click="bind_phone" class="black in_body" >绑定手机号</button>
    </div>
    <p class="warn" v-if="what_login">登录手机号，网页、APP、小程序多平台使用</p>
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import { Dialog, Toast } from 'vant'
import Utils from '@/utils/utils'

export default {
  name: 'Login',
  store,
  data () {
    return {
      niming: store.state.niming,
      time: 60,
      disabled: false,
      disabled2: false,
      btntxt: '发送验证码',
      phone: null,
      yanzhengma: null,
      what_login: false,
      is_agree: true
    }
  },
  methods: {
    to_login () {
      this.what_login = true
      const data = { tag: 'to_login' }
      axios.post('/log_sth_unlogin/', data)
        .then(res => {
          console.log('to_login')
        })
    },
    get_yanzhengma () {
      if (!this.phone) {
        Utils.alert({ content: '手机号不可为空' })
        return
      }
      if (this.phone.length !== 11) {
        Utils.alert({ content: '手机号码位数不正确' })
        return
      }
      const data = { phone: this.phone }
      axios.post('/get_yanzhengma/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.time = 60
          this.timer()
        })
    },

    timer () {
      if (this.time > 0) {
        this.disabled = true
        this.time--
        this.btntxt = this.time + '秒'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '发送验证码'
        this.disabled = false
      }
    },
    login_by_nothing () {
      if (!this.is_agree) {
        Utils.show_snackbar('请先同意用户协议和隐私政策')
        return
      }
      this.disabled2 = true
      Utils.show_loading()
      const data = { sid: localStorage.getItem('sid'), st: localStorage.getItem('st'), launch_info: { scene: null, brand: '' } }
      axios.post('/login_by_nothing/', data)
        .then(res => {
          this.disabled = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          localStorage.removeItem('sid')
          localStorage.removeItem('st')
          localStorage.setItem('token', res.data.data.token)
          store.commit('is_niming')
          location.href = store.state.base_url
        })
    },
    login_by_phone (e) {
      if (!this.is_agree) {
        Utils.show_snackbar('请先同意用户协议和隐私政策')
        return
      }
      console.log(store.state.sid)
      if (!this.phone) {
        Utils.alert({ content: '手机号不可为空' })
        return
      }
      if (this.phone.length !== 11) {
        Utils.alert({ content: '手机号码位数不正确' })
        return
      }
      if (!this.yanzhengma) {
        Utils.alert({ content: '验证码不可为空' })
        return
      }
      Utils.show_loading()
      this.disabled2 = true
      const data = { phone: this.phone, yanzhengma: this.yanzhengma, sid: localStorage.getItem('sid'), st: localStorage.getItem('st'), launch_info: { scene: null, brand: '' } }
      axios.post('/login_by_phone/', data)
        .then(res => {
          Toast.clear()
          this.disabled = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          localStorage.removeItem('sid')
          localStorage.removeItem('st')

          localStorage.setItem('token', res.data.data.token)
          store.commit('is_niming')
          Dialog.alert({
            message: '登录成功'
          }).then(() => {
            location.href = store.state.base_url
          })
        })
    },
    bind_phone () {
      if (!this.phone) {
        Utils.alert({ content: '手机号不可为空' })
        return
      }
      if (this.phone.length !== 11) {
        Utils.alert({ content: '手机号码位数不正确' })
        return
      }
      if (!this.yanzhengma) {
        Utils.alert({ content: '验证码不可为空' })
        return
      }
      Utils.show_loading()
      this.disabled2 = true
      const data = { phone: this.phone, yanzhengma: this.yanzhengma, sid: localStorage.getItem('sid') }
      axios.post('/bind_phone/', data)
        .then(res => {
          Toast.clear()
          this.disabled = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          Dialog.alert({
            message: '绑定成功'
          })
          Utils.go_to_index()
        })
    }
  }
}
</script>

<style scoped>
.page{
  padding-bottom: 0.5rem;
}
.login{
  background-image: linear-gradient(rgb(70, 130, 180), #81D3F8);
  color: #fefefe;
  text-align: center;
  padding-top:0.5rem;
}
.login div{
  color: #fefefe;
}
.login span{
  color: #fefefe;
}

.login .title{
  font-size:1rem;
  line-height:1rem;
  height: 1rem;
  padding-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.login .content{
  height: 4rem;
  position: relative;
  font-size:0.42rem;
  overflow:hidden;
}

.login .content .round{
  position: absolute;
  background-color: rgba(255,255,255,0.2);
  width: 18rem;
  height: 18rem;
  border-radius: 50rem;
  bottom: -15rem;
  left: -4.1rem;
}
.login .content .round .desc{
  margin:1rem auto 0.5rem auto;
  font-size: 0.5rem;
}
.login .content .round .ex{
  text-align: center;
}
.login .content .round .ex span{
  display: inline-block;
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.5rem;
  margin: 0.2rem 0.21rem;
  font-size: 0.4rem;
  box-shadow:0.04rem 0.04rem 0.16rem #888;
}
.login .content .name1{
  background-color: #7DABD5;
}
.login .content .name2{
  background-color: #EC6F7E;
}
.login .content .name3{
  background-color: #86BD2C;
}
.login .content .name4{
  background-color: #7984BF;
}
.content div{
  margin-bottom: 1rem;
}
/* .mod div{
  margin-bottom: 1rem;
} */
.phone input.yanzhengma{
  width: 2.8rem;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
}
.phone button.sendcode{
  width: 2.4rem;
  background: #81D3F8;
  border: none;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0rem;
  border-top-left-radius: 0rem;
  line-height: 0.9rem;
  height: 0.9rem;
  color: #fefefe;
  font-size: 0.32rem;
}
button.in_body{
  font-size: 0.4rem;
}
.login_tips{
  color: #666;
  text-align: center;
  margin:0.2rem auto 0.8rem auto;
}
.agreement_div{
  margin: 0.4rem 0rem 0.6rem 0rem;
  font-size: 0.45rem;
}
.agreement_div a{
  display: inline-block;
  margin: 0rem 0.16rem;
  color: #569CD6;
}
.phone {
  display: block;
  margin: 0.4rem 0rem;
  padding: 0.60rem 0rem;
  text-align:center;
  background-color: #fefefe;
  border-radius: 0.10rem;
}
.phone input{
  width: 5.2rem;
  border-radius: 0.40rem;
  background-color: #f8f8f8;
  text-align: left;
  display: inline-block;
  border-left: 0.8rem solid #f8f8f8;
  border-right: none;
  border-top: none;
  border-bottom: none;
  margin-top: 0.20rem;
  height: 0.90rem;
  line-height: 0.90rem;
}

.phone div{
  margin-bottom: 1rem;
}
/* .agreement_div input[type="checkbox"]{
  background-color: #86BD2C;
} */
input[type=checkbox]:checked::after{
  color: #86BD2C;
}
</style>
