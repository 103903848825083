<template>
  <div class="">
    <div v-if="!has" class="mdd">
      <GreenTips  word="按生辰八字起名" />
      <button class="in_body black" data-value="picker" @click="pull_up">输入宝宝出生日期</button>
    </div>
    <div v-else class="mdd">
      <div id="bazi" >
        <div class="header">
          <div class="weeee">
            <van-icon name="down" size='32rpx' color='#4fc08d'/>
            <span>传统文化仅做参考</span>
          </div>
          <button class="" data-value="picker" @click="pull_up">修 改</button>
        </div>
        <ul class="biaodan">
          <li class="line">
            <span class="td">公 历</span>
            <span>{{info.gongli[0]}}</span>
            <span>{{info.gongli[1]}}</span>
            <span>{{info.gongli[2]}}</span>
            <span>{{info.gongli[3]}}</span>
          </li>
          <li class="line">
            <span class="td">农 历</span>
            <span>{{info.nongli[0]}}</span>
            <span>{{info.nongli[1]}}</span>
            <span>{{info.nongli[2]}}</span>
            <span>{{info.nongli[3]}}</span>
          </li>
          <li class="line">
            <span class="td">八 字</span>
            <span>{{info.bazi[0]}}</span>
            <span>{{info.bazi[1]}}</span>
            <span>{{info.bazi[2]}}</span>
            <span>{{info.bazi[3]}}</span>
          </li>
          <li class="line">
            <span class="td">五 行</span>
            <span>{{info.wuxing[0]}}</span>
            <span>{{info.wuxing[1]}}</span>
            <span>{{info.wuxing[2]}}</span>
            <span>{{info.wuxing[3]}}</span>
          </li>
        </ul>
        <div id="tongyi">
          <div class="tongyilei">
            <span class="gray">同类: {{ info.tongyilei[0].score }}分</span>
            <span v-for="( item,index) in info.tongyilei[0].wuxing" :key="index"  :class="'wuxing_word word_color_'+item.color">{{item.word}}</span>
          </div>
          <div class="tongyilei">
            <span class="gray">异类: {{ info.tongyilei[1].score }}分</span>
            <span v-for="( item,index) in info.tongyilei[1].wuxing" :key="index"  :class="'wuxing_word word_color_'+item.color">{{item.word}}</span>
          </div>
        </div>
        <div v-if="info.desc" class="desc">说明：{{info.desc}}</div>
      </div>
      <div id='wuxing'>
        <GreenTips  word="五行得分，配字工具" />
        <ul class="biaodan">
          <li>
            <div class='touming word'>五行</div>
            <div class='tt num'>个数</div>
            <div class='tt score'>得分</div>
            <div class='tt percent'>比重</div>
            <div class='tt zi'>可配字</div>
          </li>
          <li  v-for="( item,index) in info.list" :key="index" @click="go_to_wuxing" :data-value="item.word">
            <div :class="'word word_color_'+item.color">{{item.word}}</div>
            <div class="num">{{item.num}}</div>
            <div class="score">{{item.score}}</div>
            <div class="percent">
              <van-progress :percentage="item.percent" :show-pivot='false' stroke-width='5' :color="item.color==='jin'?'#ffd200':item.color==='mu'?'#bae4a3':item.color==='shui'?'#93b7ff':item.color==='huo'?'#ffa200':'#af8673'"  />
            </div>
            <div class="zi">
              <div :class="'fit_word background_color_'+item.color">{{item.zi_num}}个 去配字></div>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <van-popup v-model:show="show.picker" round position="bottom" :style="{ height: '45%' }" @close="on_close">
      <van-picker title="" @change="onChange" @confirm="onConfirm" @cancel="onCancel" :columns="time_lists" />
    </van-popup>
  </div>
</template>

<script>

import Utils from '@/utils/utils'
import axios from 'axios'
import store from '@/store'
import router from '@/router/index.js'
import GreenTips from '@/components/items/GreenTips'

const years = []
const months = []
const days = []
const hours = ['未知']

for (let i = 2023; i >= 1980; i--) {
  years.push(i + '年')
}

for (let i = 1; i <= 12; i++) {
  months.push(i + '月')
}

for (let i = 1; i <= 31; i++) {
  days.push(i + '日')
}
for (let i = 0; i <= 23; i++) {
  hours.push(i + '点')
}

export default {
  name: 'WuXing',
  store,
  props: {
    xing: String,
    from_page: String
  },
  components: {
    GreenTips
  },
  data () {
    return {
      has: false,
      can_see_more: false,
      time_lists: [{ values: years, defaultIndex: 1 }, { values: months, defaultIndex: 1 }, { values: days, defaultIndex: 1 }, { values: hours, defaultIndex: 1 }],
      info: {},
      niming: store.state.niming,
      show: { picker: false },
      time_list: ['2022年', '1月', '1日', '0点']
    }
  },
  mounted () {
  },
  methods: {
    onChange (e) {
      console.log(e)
    },
    onConfirm (e) {
      this.on_close()
      this.time_list = e
      this.get_wuxing(e)
    },
    onCancel (e) {
      this.on_close()
    },
    get_wuxing (timeList) {
      axios.post(this.niming ? '/wuxing_index_unlogin/' : '/wuxing_index/', { time_list: timeList })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log(res.data)
          this.info = res.data.data.info
          this.can_see_more = res.data.data.can_see_more
          this.has = true
        })
    },
    go_to_wuxing (e) {
      if (this.xing === '') {
        Utils.alert({ content: '请先在首页输入姓氏或点击姓氏后，选择八字起名' })
        return
      }
      if (!this.can_see_more) {
        Utils.show_ask_goods({ page: this.from_page, tag: 'bazi' })
        return
      }
      router.push({ name: 'name_wuxing', query: { xing: this.xing, word: e.currentTarget.dataset.value } })
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    on_close () {
      this.show = { picker: false }
    },
    xml_go_to_goods_index (e) {
      Utils.go_to_goods_index(e)
    }
  }
}
</script>
<style scoped>
.mdd{
  display: block;
  margin: 0.4rem 0rem;
  padding: 0.60rem 0rem;
  text-align:left;
  background-color: #fefefe;
  border-radius: 0.10rem;
}
#bazi .biaodan,#wuxing .biaodan{
  border: 0.02rem solid #eee;
  display: inline-block;
  margin: 0.4rem 0.5rem;
  text-align: center;
}
#bazi ul li span{
  line-height: 1rem;
  height: 1rem;
  width: 1.75rem;
  display: inline-block;
  border-bottom: 0.01rem solid #eee;
  border-right: .01rem solid #eee;
  font-size: 0.4rem;
}
.td{
  color: #42b983
}
.desc{
  margin: 0rem 0.5rem 0.2rem 0.5rem;
  color:#888;
}
.weeee{
  margin-bottom: 0.2rem;
  padding-left: 0.4rem;
  display: inline-block;
  width: 7.8rem;
}
.weeee span{
  color: #888;
  margin-left: 0.3rem;
}
#bazi .header button{
  background-color: #212B36;
  color: #fefefe;
  width: 1.2rem;
  height: 0.6rem;
  border-radius: 0.2rem;
  font-size: 0.3rem;
}
.tongyilei{
  display: inline-block;
  margin: 0rem 0rem 0.2rem 0.5rem;
}
.wuxing_word{
  margin: 0rem 0.06rem;
  font-weight: bold;
}

#wuxing{
  padding: 0.5rem 0rem;
}
#wuxing ul li div{
  line-height: 1.1rem;
  height: 1.1rem;
  width: 1.1rem;
  display: inline-block;
  border-bottom: 0.01rem solid #eee;
  border-right: .01rem solid #eee;
  font-size: 0.4rem;
}
#wuxing ul li .touming{
  color:rgba(255,255,255,0)
}
#wuxing ul li  .tt{
  color: #ccc;
}

#wuxing ul li .percent{
  width: 2.2rem;
}
#wuxing ul li .zi{
  width: 3.2rem;
}
#wuxing ul li .zi div{
  width: 2.8rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.1rem;
  color: #fefefe;
  font-size: 0.3rem;
}
#wuxing .word{
  font-weight: bold;
}

</style>
