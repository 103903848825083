<template>
  <!-- <van-skeleton v-if="loading" :row="6" />
  <div v-else class="page"> -->
  <div class="page">
    <div v-if="see_to_app && is_ios && !can_see_more && sid=='zhbue2'" id="to_app_div_zanwei" ></div>
    <van-swipe id="head" class="my-swipe" autoplay="3000" indicator-color="rgba(255, 255, 255, .5)" indicator-active-color="#fff" >
      <van-swipe-item  v-for="( item,index) in good_name_list" :key="index" >
        <NameHead :name_info="item" :can_click="true" />
      </van-swipe-item>
    </van-swipe>
    <div class="mod">
      <ul v-if="xing_list.length" id="logs">
        <p class='green_tips'>
          <van-icon name="down" size='32rpx' color='#4fc08d'/>
          <span>去姓首页</span>
        </p>
        <li v-for="xing in xing_list" :key="xing">
          <span class="xing" :data-value="xing" @click="click_xing">{{xing}}</span>
        </li>
        <li class="add" @click="want_add_xing">添加</li>
      </ul>
      <div v-else>
        <p id="tiyan">
          <span>体验大数据服务</span>
          <van-icon name="down" size='32rpx' color='#4fc08d'/>
        </p>
        <button  class="in_body black" @click="want_add_xing"  >输入宝宝姓氏</button>
      </div>
    </div>
    <van-swipe  class="marks my-swipe" vertical="true" autoplay="1600" indicator-color="rgba(255, 255, 255, .5)" indicator-active-color="#fff" >
      <van-swipe-item  v-for="( item,index) in mark_name_list" :key="index" >
        <div>
          <img :src="item.user.avatar" />
          <span class="gray">{{item.user.nickname}}</span>
          <span class="gray">收藏了名字</span>
          <!-- <a :class="'word_'+ item.name_info.color" :href="{name : 'name_detail', query : {name : item.name_info.word}}">{{item.name_info.word}}</a> -->
          <router-link  :class="'word_'+ item.name_info.color" :to="{name : 'name_detail', query : {name : item.name_info.word}}"  >{{item.name_info.word}}</router-link>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="buttons">
      <router-link :to="{name:'group_how'}"><span>起名技巧</span></router-link>
      <router-link :to="{name:'group_exam'}"><span>起名盲区</span></router-link>
      <router-link :to="{name:'group_worth'}"><span>名字重要性</span></router-link>
      <span data-value="index|" @click="xml_go_to_goods_index">成为会员</span>
    </div>
    <div v-if="!niming" >
      <SearchName></SearchName>
    </div>
    <div v-else>
      <WuXing xing='' from_page='index'></WuXing>
    </div>
    <!-- <div v-else class="mod single" >
      <button  class="in_body lightgray" data-value="input_name" @click="click_button"  >输入名字（姓+名）</button>
      <button  class="in_body black" data-value="button_name" @click="click_button"  >姓名分析</button>
    </div> -->
    <!-- <div v-else class="mod single">
      <input  v-model="name_str" placeholder-class="placeholder"  type="text" placeholder="输入名字（姓+名）" />
      <button class="black in_body"  @click="get_name_str" >姓名分析</button>
    </div> -->
    <!-- <button  v-if="!loading && !can_see_more" class="to_be_vip" data-value="index|bottom" @click="xml_go_to_goods_index">成为会员</button> -->

    <div class="mcd">
      <van-skeleton v-if="loading" :row="8" />
      <div  v-else>
        <GreenTips word="好听名字参考" />
        <router-link :class="'one_name background_'+ item.color"  v-for="( item,index) in list.best" :key="index" :to="{name : 'name_detail', query : {name : item.word}}" >{{item.word}}</router-link>
        <van-skeleton v-if="loading2" row="5" />
        <p v-if="!niming  && !loading && !loading2 && page.best !== -1" class="more"  @click="get_good_names">更 多</p>
        <p v-if="niming && !loading && !loading2 " class="more" data-value="zc" @click="pull_up"  >更 多</p>
        <p v-if="!niming && !loading  && !loading2 && page.best === -1"  class="end">-- End --</p>
      </div>
    </div>
    <div class="mod" v-if="xing_list.length==0" >
      <p id="tiyan">
        <span>用数据拓展起名思路</span>
        <van-icon name="down" size='32rpx' color='#4fc08d'/>
      </p>
      <button  class="in_body black" @click="want_add_xing"  >输入宝宝姓氏</button>
    </div>
    <button v-else-if="!niming && !loading && !can_see_more"   class="to_be_vip" data-value="index|bottom" @click="xml_go_to_goods_index">了解更多大数据起名功能</button>

    <div v-if="niming" class="tips">
      <p>泰安道听途想网络科技有限公司</p>
      <p>鲁ICP备20026180号-3</p>
      <div style="vertical-align:middle;">
        <img style="width:0.4rem;high:0.4rem; margin-right:0.08rem;" src="@/assets/img/gongan.png"  alt="">
        <span style="font-size: 0.45rem;">37092102000111</span>
      </div>
    </div>

    <ul class="home_footer">
      <li class="icons active_icons"><router-link :to="{name:'index'}">首页</router-link></li>
      <li class="icons un_index"><router-link :to="{name:'mine_index'}">我的</router-link></li>
    </ul>
    <ul id="last_right_a" class="last_right" >
      <li class="kefu" data-value="contact"  @click="pull_up" ><img src="@/assets/img/kefu.png" /></li>
    </ul>
    <div v-if="see_to_app && is_ios && !can_see_more && sid=='zhbue2'" id="to_app_div"  >
      <button @click="to_app_store"  data-value="index_big">下载 花样起名APP</button>
      <div class="close" @click="on_close_to_app"><van-icon name="clear" color="#ccc" size="0.8rem" /></div>
    </div>
    <div v-if="!see_to_app && is_ios && !can_see_more" id="to_app_div_small" data-value="index_small"  @click="to_app_store" >下载</div>
    <van-popup v-model:show="show.zc" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Login></Login>
    </van-popup>
    <van-popup v-model:show="show.contact" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Contact></Contact>
    </van-popup>
    <van-popup v-model:show="show.add_xing" round position="bottom" :style="{ height: '80%' }" @close="on_close">
      <div  v-if="niming" class="mod" >
        <div>
          <input v-model="xing_str" placeholder-class="placeholder" type="text" placeholder="输入宝宝姓氏" />
        </div>
        <button class="in_body black" @click="login_and_add_xing">确 定</button>
        <div class="agreement_div">
          <input v-model='is_agree'  type="checkbox" name="default"> <label for="default">同意</label>
          <router-link class="" :to="{name:'agreement',query:{type:'agreement'}}"><span>用户协议</span></router-link>
          <span>和</span>
          <router-link :to="{name:'agreement',query:{type:'secret'}}"><span>隐私政策</span></router-link>
        </div>
      </div>
      <div v-else class="mod" >
        <p v-if="xing_list.length && tips.text.need"  class="warn_tips">{{ tips.text.str }}</p>
        <div>
          <input v-model="xing_str" placeholder-class="placeholder" type="text" placeholder="输入宝宝姓氏" />
        </div>
        <button class="in_body black" @click="add_xing">确 定</button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import { Dialog } from 'vant'
import Login from '@/components/other/Login'
import Contact from '@/components/other/Contact'
import NameHead from '@/components/items/NameHead'
import GreenTips from '@/components/items/GreenTips'
import SearchName from '@/components/other/SearchName'
import WuXing from '@/components/other/WuXing'
import router from '@/router/index.js'
// import LoginAndAddXing from '@/components/other/LoginAndAddXing'

export default {
  name: 'index',
  store,
  components: {
    Login,
    Contact,
    NameHead,
    SearchName,
    GreenTips,
    WuXing
    // LoginAndAddXing
  },
  data: function () {
    return {
      loading: true,
      show: { zc: false, add_xing: false, contact: false },
      niming: store.state.niming,
      sid: '',
      see_to_app: true,
      base_img: store.state.base_img,
      is_ios: Utils.is_ios(),
      is_agree: true,
      xing_list: [],
      more: false,
      can_see_more: false,
      tips: { text: { need: false } },
      phone: '',
      yanzhengma: '',
      xing_str: '',
      disabled: false,
      good_name_list: [],
      mark_name_list: [],
      store_info: { id: '', urlscheme: '' },
      list: { best: [] },
      page: { best: 1 },
      index_to_where: 'xing_unvip'
    }
  },
  created () {
    this.get_index()
  },
  methods: {
    get_index () {
      this.sid = this.$route.query.sid
      axios.post(this.niming ? '/index_unlogin/' : '/index/', { sid: this.$route.query.sid })
        .then(res => {
          // console.log(res)
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.xing_list = res.data.data.xing_list
          this.good_name_list = res.data.data.good_name_list
          this.mark_name_list = res.data.data.mark_name_list
          this.mark_name_list_2 = res.data.data.mark_name_list_2
          this.can_see_more = res.data.data.can_see_more
          this.index_to_where = res.data.data.index_to_where
          this.store_info = res.data.data.store_info
          this.loading = false
          const ResList = res.data.data.list
          if (ResList.length === 20) {
            this.list.best = this.list.best.concat(ResList)
            this.page.best = this.page.best + 1
          } else if (ResList.length !== 0) {
            this.list.best = this.list.best.concat(ResList)
            this.page.best = -1
          } else {
            this.page.best = -1
          }
          Utils.alert_tip_list('index', res.data.data.tip_list)
        })
    },
    get_good_names: function () {
      this.loading2 = true
      axios.post('/good_name_list/', { page: this.page.best })
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          if (res.data.data.need_vip) {
            Utils.show_ask_goods({ page: 'index', tag: 'good_name' })
            return
          }
          const ResList = res.data.data.list
          if (ResList.length === 20) {
            this.list.best = this.list.best.concat(ResList)
            this.page.best = this.page.best + 1
          } else if (ResList.length !== 0) {
            this.list.best = this.list.best.concat(ResList)
            this.page.best = -1
          } else {
            this.page.best = -1
          }
        })
    },
    click_xing: function (e) {
      this.go_to_xing(e.currentTarget.dataset.value)
    },
    want_add_xing: function () {
      if (this.niming) {
        this.show.add_xing = true
        return
      }
      axios.post('/want_more_xing/', {})
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.tips = res.data.data.tips
          this.show.add_xing = true
        })
    },
    add_xing: function (e) {
      if (!this.xing_str) {
        Utils.alert({ content: '输入内容不可为空' })
        return
      }
      axios.post('/user_add_xing/', { word: this.xing_str })
        .then(res => {
          this.show.add_xing = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.xing_list = res.data.data.xing_list
          Dialog.alert({
            message: '添加成功，下次可在首页直接点击',
            confirmButtonText: '朕知道了',
            confirmButtonColor: '#4fc08d'
          }).then(() => {
            this.go_to_xing(this.xing_list[this.xing_list.length - 1])
          })
        })
    },
    login_and_add_xing () {
      if (!this.is_agree) {
        Utils.show_snackbar('请先同意用户协议和隐私政策')
        return
      }
      this.disabled2 = true
      Utils.show_loading()
      const data = { word: this.xing_str, sid: localStorage.getItem('sid'), st: localStorage.getItem('st'), launch_info: { scene: null, brand: '' } }
      axios.post('/login_and_add_xing/', data)
        .then(res => {
          this.disabled = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          localStorage.removeItem('sid')
          localStorage.removeItem('st')
          localStorage.setItem('token', res.data.data.token)
          store.commit('is_niming')
          this.niming = false
          this.xing_list = res.data.data.xing_list
          this.show.add_xing = false
          Dialog.alert({
            message: '添加成功，下次可在首页直接点击',
            confirmButtonText: '朕知道了',
            confirmButtonColor: '#4fc08d'
          }).then(() => {
            axios.defaults.headers.Authorization = 'Bearer ' + localStorage.token
            this.go_to_xing(this.xing_list[this.xing_list.length - 1])
          })
        })
    },
    go_to_xing (xingWord) {
      if (this.can_see_more || this.index_to_where === 'xing') {
        router.push({ name: 'name_xing', query: { word: xingWord } })
      } else {
        router.push({ name: 'name_xing_unvip', query: { word: xingWord } })
      }
    },
    click_button (e) {
      axios.post(!this.niming ? '/log_sth/' : '/log_sth_unlogin/', { tag: e.currentTarget.dataset.value })
        .then(res => {
          this.show.zc = true
        })
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    xml_go_to (e) {
      if (!localStorage.getItem('token')) {
        this.show.zc = true
        return
      }
      Utils.go_to(e)
    },
    on_close () {
      this.show = { zc: false, add_xing: false, contact: false }
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    xml_go_to_goods_index (e) {
      if (!localStorage.getItem('token')) {
        this.show.zc = true
        return
      }
      Utils.go_to_goods_index(e)
    },
    to_app_store: function (e) {
      Utils.go_to_app(e.currentTarget.dataset.value, this.$router.query.sid)
    },
    on_close_to_app: function () {
      this.see_to_app = false
    }
  }
}

</script>
<style scoped>
.page{
  padding-bottom: 3.6rem;
}
#head{
  height: 4.8rem;
}
#head a{
  display: block;
}
#logs{
  text-align: left;
  margin-left: 0.60rem;
}

#logs li{
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  line-height: 1.4rem;
  margin:0rem 0.4rem 0.4rem 0rem;
  border: 0.02rem dashed #212b36;
  border-radius: 0.3rem;
  text-align: center;
  vertical-align: top;
}
#logs .xing{
  font-size: 0.8rem;
}
#logs .add{
  font-size: 0.48rem;
}

.mod .more{
  padding-bottom: 0.5rem;
}
.marks{
  height: 1.6rem;
}
.marks div{
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
  color: #888;
}
.marks div img{
  width: 0.8rem;
  height:  0.8rem;
  border-radius: 1rem;
  vertical-align: middle;
  margin-right: 0.1rem;
  line-height:  0.8rem;
}
.marks div span{
  vertical-align: middle;
  margin: 0rem 0.1rem;
  line-height:  1rem;
}
.marks div a{
  display: inline-block;
  vertical-align: middle;
  line-height:  1rem;
}

a.word_color_1{
  color:#FFB6C1;
}
a.word_color_2{
   color: #DBBFD1;
}
a.word_color_3{
   color: #C1C5DD;
}
a.word_color_4{
   color: #A6CBE9;
}
a.word_color_5{
   color: #81D3F8;
}
.buttons {
  text-align: center;
  background-color: #fefefe;
  padding:0.3rem 0.3rem;
  margin:0.4rem 0rem;
}
.buttons span{
  display: inline-block;
  width: 3.6rem;
  height: 1rem;
  line-height: 1rem;
  margin:0.3rem 0.4rem;
  color:#fefefe;
  background-color: #81D3F8;
  text-align: center;
  border-radius: 0.2rem;
  font-size: 0.4rem;
}
.single{
  width: 10rem;
  margin: 0rem auto ;
}
.single input{
  display: inline-block;
  height:  0.9rem;
  line-height: 0.9rem;
  width: 5.2rem;
  border-radius: 0.50rem;
  background-color: #f8f8f8;
  text-align: left;
  border-left: 0.8rem solid #f8f8f8;
  border-right: none;
  border-top: none;
  border-bottom: none;
}
#to_app_div{
  position: fixed;
  background-color: #fefefe;
  top: 0rem;
  width: 9.4rem;
  margin: 0rem 0.3rem 0rem 0.3rem;
  border-radius: 0.3rem;
  box-shadow: 0.2rem 0.2rem 0.3rem #888888;
}
#to_app_div_zanwei{
  width: 10rem;
  height: 3.2rem;
  background-color: #fefefe;
}
#to_app_div .close{
  position: absolute;
  right:-0rem;
  top:0.1rem;
  width: 0.8rem;
  height: 0.8rem;
}

#to_app_div button{
  width: 8rem;
  height: 1.4rem;
  background-color: #81D3F8;
  color: #fefefe;
  margin: 0.8rem 0.7rem 0.6rem 0.7rem;
  font-size: 0.6rem;
  border-radius: 0.3rem;
}
#tiyan{
  margin-top: 0.3rem;
  vertical-align: bottom;
}

#tiyan span{
  color: orange;
  margin-right: 0.2rem;
}
.one_name{
  font-size: 0.4rem;
  display: inline-block;
  height:0.75rem;
  line-height: 0.75rem;
  padding: 0.06rem 0.3rem 0rem 0.3rem;
  text-align: center;
  border-radius: 0.8rem;
  color: #fefefe;
  margin: 0.3rem 0.2rem 0.3rem 0.2rem;
  box-shadow:0.02rem 0.02rem 0.1rem #888;
}
button.to_be_vip{
  margin-top: 0.1rem;
}
.mcd .more{
  color: #81D3F8;
  background-color:rgba(129, 211, 248, 0.1);
  display: inline-block;
  padding: 0.18rem 0.48rem;
  margin: 0.2rem 0.2rem 0rem 0.2rem;
  border-radius: 0.5rem;
  text-align: center;
}
.agreement_div{
  margin: 0.9rem 0rem 0.6rem 0rem;
  font-size: 0.45rem;
}
.agreement_div a{
  display: inline-block;
  margin: 0rem 0.16rem;
  color: #569CD6;
}
</style>
