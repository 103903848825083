<template>
  <div class="weeee">
    <van-icon name="down" size='32rpx' color='#4fc08d'/>
    <span>{{word}}</span>
  </div>
</template>

<script>
export default {
  name: 'GreenTips',
  props: {
    word: String
  }
}
</script>
<style>
.weeee{
  margin-bottom: 0.2rem;
  padding-left: 0.4rem;
}
.weeee span{
  color: #888;
  margin-left: 0.3rem;
}
</style>
