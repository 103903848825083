<template>
  <van-skeleton v-if="loading" :row="3" />
  <div v-else class="page">
    <div class="mwd">
      <p class="warn">我们非常在意您的体验</p>
      <p class="warn">{{contact_info.desc}}</p>
      <img :src="contact_info.img_url">
    </div>
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'

export default {
  name: 'Contact',
  store,
  data () {
    return {
      loading: true,
      niming: store.state.niming,
      base_img: store.state.base_img,
      contact_info: {
        desc: '请通过微信客服联系我们',
        img_url: '',
        way: ''
      }
    }
  },
  mounted () {
    this.get_contact()
  },
  methods: {
    get_contact () {
      const urlStr = this.niming ? '/get_contact_unlogin/' : '/get_contact/'
      axios.post(urlStr, {})
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.loading = false
          this.contact_info = res.data.data.contact_info
          if (this.contact_info.img_url.substr(1, 5) !== 'https') {
            this.contact_info.img_url = this.base_img + this.contact_info.img_url
          }
        })
    }
  }
}
</script>

<style scoped>
.mwd{
  margin-top: 1rem;
  padding-top: 0.4rem;
  text-align: center;
}
.mwd p{
  margin: 0.4rem 0rem;
}
.mwd img{
  width: 5rem;
  height: 5rem;
  margin: 1rem;
}

</style>
